<template>
  <div>
    <div class="order-ok">
      <van-icon name="arrow-left" @click="this.$router.back(-1)" />
      <div class="order-ok-text">编辑地址</div>
      <van-icon name="ellipsis" />
    </div>
    <div class="layout">
      <div class="editor-card">
        <van-cell-group>
          <van-field
            v-model="uaerName"
            label="姓名"
            placeholder="请输入用户名"
          />
          <van-field
            v-model="mobile"
            type="number"
            label="电话"
            placeholder="请输入手机号"
          />
          <van-field
            v-model="fieldValue"
            is-link
            readonly
            label="地区"
            placeholder="请选择所在地区"
            @click="showPopup = true"
          />
          <van-field
            v-model="detailAddress"
            label="详细地址"
            placeholder="街道门牌、楼层房间号信息"
          />
          <van-field
            v-model="postalCode"
            label="邮政编码"
            placeholder="邮政编码"
          />
        </van-cell-group>
      </div>
      <div class="default-address">
        <span>设置为默认地址</span>
        <van-switch v-model="openDefault" />
      </div>
      <div class="operation-button">
        <van-button type="danger" block round @click="saveBtn">保存</van-button>
        <van-button
          type="danger"
          block
          round
          plain
          class="del-btn"
          @click="deleteBtn"
          >删除</van-button
        >
      </div>
    </div>
    <van-popup v-model="showPopup" round position="bottom">
      <!-- 联级选择 -->
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="options"
        :field-names="fieldNames"
        @finish="chooseComplete"
        @close="onClose"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "address-update",
  components: {},
  data() {
    return {
      openDefault: true, // 默认地址开关
      areaList: {},
      searchResult: [],
      uaerName: "",
      mobile: "",
      addressValue: "",
      detailAddress: "",
      postalCode: "",
      showPopup: false, // 弹出层控制
      fieldValue: "",
      cascaderValue: "",
      fieldNames: {
        text: "name",
        value: "code",
        children: "items",
      },
      options: [
        {
          name: "浙江省",
          code: "330000",
          items: [{ name: "杭州市", code: "330100" }],
        },
        {
          name: "江苏省",
          code: "320000",
          items: [{ name: "南京市", code: "320100" }],
        },
      ],
    };
  },
  methods: {
    chooseAddress() {},
    saveBtn() {},
    deleteBtn() {},
    chooseComplete() {
      // 选择完成后需要做什么
      this.showPopup = false;
    },
    onClose() {
      // 点击关闭图标时需要做什么
      this.showPopup = false;
    },
    onSave() {
      Toast("save");
    },
    onDelete() {
      Toast("delete");
    },
    onChangeDetail(val) {
      if (val) {
        this.searchResult = [
          {
            name: "黄龙万科中心",
            address: "杭州市西湖区",
          },
        ];
      } else {
        this.searchResult = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.order-ok {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  font-size: 22px;
  padding: 0 12px;
  background-color: #fff;
  .order-ok-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.layout {
  padding: 12px;
}
.editor-card {
  border-radius: 16px;
  background-color: #fff;
  overflow: hidden;
}
.van-cell__title {
  flex: none;
}
.van-cell__value {
  padding-left: 72px;
  flex: none;
}
.van-cell {
  position: relative;
}
.van-icon-arrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.default-address {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 16px;
  padding: 12px;
}
.operation-button {
  padding: 12px;
}
.del-btn {
  margin-top: 12px;
}
</style>
